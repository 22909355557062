import { Service, StandaloneService } from '@moxi.gmbh/moxi-typescriptmodels';
import { InfoType } from '@shared/models';

export type StandaloneServiceInfo = InfoType<StandaloneService> & {
  icon?: string;
  url?: string;
};

export type ServiceInfo = InfoType<Service>;

export const standaloneServiceDetails: StandaloneServiceInfo[] = [
  {
    id: 'pro_medical',
    name: 'shared.serviceDetails.pro_medical.name',
    description: 'shared.serviceDetails.pro_medical.description',
    icon: 'medicine-box',
    url: '/account/create-medical-account'
  },
  {
    id: 'pro_fleet',
    name: 'shared.serviceDetails.pro_fleet.name',
    description: 'shared.serviceDetails.pro_fleet.description',
    icon: 'truck',
    url: '/account/create-fleet-account'
  },
  {
    id: 'pro_operator',
    name: 'shared.serviceDetails.pro_operator.name',
    description: 'shared.serviceDetails.pro_operator.description',
    icon: 'customer-service',
    url: '/account/create-operator-account'
  }
];

export const serviceDetails: ServiceInfo[] = [
  ...standaloneServiceDetails,
  {
    id: 'celios_integration',
    name: 'shared.serviceDetails.celios_integration.name',
    description: 'shared.serviceDetails.celios_integration.description'
  },
  {
    id: 'dispolive_integration',
    name: 'shared.serviceDetails.dispolive_integration.name',
    description: 'shared.serviceDetails.dispolive_integration.description'
  },
  {
    id: 'moxi_operations',
    name: 'shared.serviceDetails.moxi_operations.name',
    description: 'shared.serviceDetails.moxi_operations.description'
  }
];
