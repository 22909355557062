import { VehicleType } from '@moxi.gmbh/moxi-typescriptmodels';
import { InfoType } from '@shared/models';

export type VehicleTypeInfo = InfoType<VehicleType>;

export const vehicleTypes: VehicleTypeInfo[] = [
  {
    id: 'ambulance',
    name: 'shared.vehicleTypes.ambulance'
  },
  {
    id: 'taxi',
    name: 'shared.vehicleTypes.taxi'
  },
  {
    id: 'emergencyAmbulance',
    name: 'shared.vehicleTypes.emergencyAmbulance'
  },
  {
    id: 'intensiveCareAmbulance',
    name: 'shared.vehicleTypes.intensiveCareAmbulance'
  },
  {
    id: 'intensiveCareHelicopter',
    name: 'shared.vehicleTypes.intensiveCareHelicopter'
  }
];

export const intensiveCareVehicles: VehicleType[] = [
  'emergencyAmbulance',
  'intensiveCareAmbulance',
  'intensiveCareHelicopter'
];
