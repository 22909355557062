import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments';

export type DocumentType = 'businessLicense' | 'publicLiabilityPolicy';

export interface DocumentUploadResponse {
  id: string;
  accountId: string;
  documentType: DocumentType;
  fileName: string;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentHttpService {
  private readonly http = inject(HttpClient);
  private readonly baseUrl = `${environment.apiHost}/documents`;

  /**
   * Uploads a document to the server
   *
   * @param file The file to upload
   * @param fileName Name of the file with extension
   * @param accountId ID of the account the document belongs to
   * @param documentType Type of document being uploaded
   * @returns Observable of the upload response
   */
  upload(
    file: File | Blob,
    fileName: string,
    accountId: string,
    documentType: DocumentType
  ): Observable<HttpEvent<DocumentUploadResponse>> {
    const formData = new FormData();
    formData.append('file', file);

    const params = new HttpParams()
      .set('fileName', fileName)
      .set('accountId', accountId)
      .set('documentType', documentType);

    return this.http.post<DocumentUploadResponse>(this.baseUrl, formData, {
      reportProgress: true,
      observe: 'events',
      params
    });
  }

  /**
   * Retrieves a document by its ID
   * @param documentId ID of the document to retrieve
   * @returns Observable of the document blob
   */
  download(documentId: string): Observable<Blob> {
    const params = new HttpParams().set('id', documentId);
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf'
    });

    return this.http.get(this.baseUrl, {
      params,
      headers,
      responseType: 'blob'
    });
  }

  /**
   * Deletes a document by its ID
   * @param documentId ID of the document to delete
   * @returns Observable of the operation result
   */
  delete(documentId: string): Observable<void> {
    const params = new HttpParams().set('id', documentId);

    return this.http.delete<void>(this.baseUrl, { params });
  }
}
